@import 'app.scss';
.container {
  grid-column: 1 / -1;
  border-radius: var(--border-radius-large);
  background-color: var(--color-bg-default);
  padding: spacing(8);

  @include from-breakpoint(lg) {
    padding: spacing(12);
  }
}

.question {
  display: grid;
  white-space: pre;

  @include from-breakpoint(sm) {
    display: flex;
    flex-wrap: wrap;
  }
}

.selectWrapper {
  display: inline-block;
  margin: spacing(2) spacing(1) spacing(2) spacing(1);
}

.submit {
  display: block;
  flex-shrink: 0;
}

.form {
  display: flex;
  flex-direction: column;
  gap: spacing(4);
  align-items: flex-start;
}
